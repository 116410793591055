import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer id='site-footer'>
    <nav>
      <ul>
        <li key="home"><Link to='/'>Home</Link></li>
        <li key="about"><Link to='/about'>About</Link></li>
        <li key="contact"><Link to='/contact'>Contact</Link></li>
      </ul>
    </nav>
    <div className="hr" />
    <blockquote>
      Through [Jesus] let us continually offer up a sacrifice of praise to God, that is, the fruit of lips that acknowledge His name.<br />
      <cite>— Hebrews 13:15</cite>
    </blockquote>
  </footer>
)

export default Footer
