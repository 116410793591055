import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header id='site-header'>
    <nav>
      <Link
        to='/'
        className='logo'
      >
        <div id="logo-container">
          hymnsonline.org
          <div className="tagline">an online supplement</div>
        </div>
      </Link>
      <ul>
        <li key="about" className="secondary"><Link to='/about'>About</Link></li>
        <li key="contact" className="secondary"><Link to='/contact'>Contact</Link></li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
