// Based on https://github.com/Faithlife/react-reftagger/blob/81e7868963972fcad4fcd828c345558c12750ce3/index.js

import { useLayoutEffect, useEffect } from "react";

export default function RefTagger() {
  useLayoutEffect(() => {
    window.refTagger = null;
    window.refTaggerCallback = null;
    var existingInjectorScript = document.getElementById('refTaggerScriptInjector');
    if (existingInjectorScript) {
      existingInjectorScript.remove();
    }
    var existingScript = document.getElementById('refTaggerScript');
    if (existingScript) {
      existingScript.remove();
    }
    const script = document.createElement("script");
    script.id = 'refTaggerScriptInjector';
    script.innerHTML = `
      var refTagger = {
        settings: {
          bibleVersion: "ESV",
          noSearchClassNames: ["reftagger-ignore"],
          roundCorners: true,
          socialSharing: [],
          customStyle : {
            heading: {
              fontFamily: "ten-oldstyle, Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif",
              fontSize: '1em',
              backgroundColor: '#fff' // avoid RefTagger console warning
            },
            body: {
              fontFamily: "ten-oldstyle, Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif",
              fontSize: '1em',
              color: '#000',
              moreLink: {
                color: '#0080FF' // avoid RefTagger console warning
              }
            }
          }
        }
      };
      (function() {
        var refTaggerScript = document.createElement('script');
        refTaggerScript.id = 'refTaggerScript';
        refTaggerScript.src = "//api.reftagger.com/v2/RefTagger.js";
        document.body.appendChild(refTaggerScript);
      }());
    `;
    document.body.appendChild(script);
  });

  return null;
}
