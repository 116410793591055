import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "../components/layout"

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <h1>Contact</h1>
      <p>We welcome critical feedback, suggestions, and requests for hymns. Simply email Adam at <a href="mailto:adam@hymnsonline.org">adam@hymnsonline.org</a>.</p>
    </Layout>
  )
}

