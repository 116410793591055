import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import Header from "./header"
import Footer from "./footer"
import RefTagger from "./RefTagger"
import "./layout.css"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta name="apple-mobile-web-app-title" content="hymnsonline.org" />
    </Helmet>
    <div id="site-container">
      <Header siteTitle="hymnsonline.org" />
      <main>{children}</main>
      <RefTagger />
      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
